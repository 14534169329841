import {
  Flex,
  Heading,
  Icon,
  Row,
  styled,
  StyledTable,
  Text,
  useTheme,
} from '@conteg/ui';
import {
  displayOnlyAllowedActions,
  passesRoleNameSomeAction,
} from 'pages/roles-documentation/helper-functions';
import { Rule } from 'pages/roles-documentation/types';
import { useTranslation } from 'react-i18next';

type TableRolesProps = {
  resource: string;
  rules: Array<Rule>;
  rolesFiltr: string[] | null;
  roleNames: string[];
};

export const TableRoles = ({
  rules,
  resource,
  rolesFiltr,
  roleNames,
}: TableRolesProps) => {
  const { t } = useTranslation();

  if (rolesFiltr && !passesRoleNameSomeAction(rules, rolesFiltr)) {
    return null;
  }

  const roles = rolesFiltr ? rolesFiltr : roleNames;

  return (
    <Flex flexDirection="column" gap="2rem">
      <Heading
        title={t(`RolesDocumentation.Resource.${resource}`)}
        variant="h2"
      />

      <StyledTable isLayoutFixed>
        <thead>
          <tr>
            <Th colSpan={2}>
              <Text content={t('RolesDocumentation.Action')} />
            </Th>

            {roles.map((role) => (
              <Th key={role}>
                <Text content={t(`RolesDocumentation.Role.${role}`)} />
              </Th>
            ))}
          </tr>
        </thead>
        <tbody>
          {rules.filter(displayOnlyAllowedActions(rolesFiltr)).map((rule) => (
            <Row key={rule.action}>
              <Td colSpan={2}>{rule.actionName}</Td>
              {roles.map((role) => (
                <TdContent key={role} roleToCheck={role} rule={rule} />
              ))}
            </Row>
          ))}
        </tbody>
      </StyledTable>
    </Flex>
  );
};

type TdContentProps = {
  roleToCheck: string;
  rule: Rule;
};

const TdContent = ({ rule, roleToCheck }: TdContentProps) => {
  const passes = rule.allowedRoles.includes(roleToCheck);
  const theme = useTheme();
  const isProviderOnlyPass = passes && rule.providerOnly;

  return (
    <Td hasYellowBackground={isProviderOnlyPass}>
      <Flex justifyContent="center">
        {passes && (
          <Icon name="checkCircle" color={theme.colors.additional.success} />
        )}
      </Flex>
    </Td>
  );
};

const Td = styled.td<{ hasYellowBackground?: boolean }>`
  background-color: ${({ hasYellowBackground, theme }) =>
    hasYellowBackground ? theme.providerColor : 'unset'};
  border-right: 0.125rem solid ${({ theme }) => theme.colors.secondary.grey100};
`;

const Th = styled.th`
  border-right: 0.125rem solid ${({ theme }) => theme.colors.secondary.grey100};
  font-size: 3rem;
  p {
    white-space: pre-line;
  }
`;
