import { alertTimeoutInMillis } from 'config';
import {
  createContext,
  PropsWithChildren,
  useCallback,
  useContext,
  useEffect,
  useRef,
  useState,
} from 'react';
import { AlertModel } from 'utils/types';

type AlertContextProps = {
  alert: AlertModel | null;
  showSuccess: (message: string) => void;
  showError: (message: string) => void;
  showWarning: (message: string) => void;
  clear: () => void;
};

const AlertContext = createContext<AlertContextProps>({
  alert: {
    message: 'test',
    type: 'success',
  },
  showSuccess: () => {
    throw new Error('useAlertContext must be used within a AlertProvider');
  },
  showError: () => {
    throw new Error('useAlertContext must be used within a AlertProvider');
  },
  showWarning: () => {
    throw new Error('useAlertContext must be used within a AlertProvider');
  },
  clear: () => {
    throw new Error('useAlertContext must be used within a AlertProvider');
  },
});

const AlertProvider = ({ children }: PropsWithChildren) => {
  const [alert, setAlert] = useState<AlertModel | null>();
  const timerRef = useRef<number>();

  useEffect(() => {
    return () => window.clearTimeout(timerRef.current);
  }, []);

  const resetTimeout = useCallback((timeoutInMillis: number) => {
    if (timerRef.current) window.clearTimeout(timerRef.current);
    timerRef.current = window.setTimeout(() => {
      clear();
    }, timeoutInMillis);
  }, []);

  const setAlertWithTimeout = useCallback(
    (
      message: string,
      type: 'success' | 'error' | 'warning',
      timeoutInMillis: number
    ) => {
      setAlert({
        message,
        type,
      });
      resetTimeout(timeoutInMillis);
    },
    [resetTimeout]
  );

  const showSuccess = (
    message: string,
    timeoutInMillis: number = alertTimeoutInMillis
  ) => {
    setAlertWithTimeout(message, 'success', timeoutInMillis);
  };

  const showWarning = (
    message: string,
    timeoutInMillis: number = alertTimeoutInMillis
  ) => {
    setAlertWithTimeout(message, 'warning', timeoutInMillis);
  };

  const showError = (
    message: string,
    timeoutInMillis: number = alertTimeoutInMillis
  ) => {
    setAlertWithTimeout(message, 'error', timeoutInMillis);
  };

  const clear = () => {
    setAlert(null);
  };

  return (
    <AlertContext.Provider
      value={{
        showSuccess,
        showError,
        showWarning,
        clear,
        alert: alert ?? null,
      }}
    >
      {children}
    </AlertContext.Provider>
  );
};

export default AlertProvider;

export const useAlertContext = () => {
  return useContext(AlertContext);
};
