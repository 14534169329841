import {
  Box,
  Button,
  DebouncedSearchInput,
  Flex,
  Heading,
  Pagination,
  showAlertModalError,
  Table,
  Tooltip,
} from '@conteg/ui';
import {
  ColumnDef,
  createColumnHelper,
  getCoreRowModel,
  useReactTable,
} from '@tanstack/react-table';
import AddInvitationModal from 'components/add-invitation-modal/invitation-create';
import { TableWrapper } from 'components/styled';
import { useInvalidateQueries } from 'hooks/use-invalidate-query';
import { useCallback, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
  InvitationsDocument,
  InvitationTableItemDto,
  useDeleteInvitationMutation,
  useInvitationsQuery,
} from 'types/generated/graphql';
import { useAlertContext } from 'utils/alert-provider';
import { pageSize } from 'utils/constants';
import booleanToKey from 'utils/formatters/boolean-formatter';
import { formatDateTime } from 'utils/formatters/datetime-formatter';
import { substituteRouteParams } from 'utils/routing/helpers';
import { RouteUrls } from 'utils/routing/router';

const invitationsColumnHelper = createColumnHelper<InvitationTableItemDto>();

const CompanyInvitations = ({ companyId }: { companyId: string }) => {
  const { t } = useTranslation();
  const { showError, showSuccess } = useAlertContext();
  const [searchTerm, setSearchTerm] = useState<string | null>(null);

  const [pageIndex, setPageIndex] = useState(0);

  const invalidate = useInvalidateQueries();

  const { data, isLoading, error } = useInvitationsQuery({
    companyId,
    pageIndex,
    pageSize,
    searchKey: searchTerm,
  });

  const pageCount = data?.invitations?.totalPages ?? -1;

  const { mutateAsync: deleteInvitation } = useDeleteInvitationMutation();

  const handleDeleteInvitation = useCallback(
    (invitationId: string) => {
      deleteInvitation(
        { id: invitationId },
        {
          onSuccess: () => {
            invalidate([InvitationsDocument]);
            showSuccess(
              t('DetailCompanyInvitations.Table.Action.Delete.Success')
            );
          },
          onError: (err) => {
            showAlertModalError(
              t('DetailCompanyInvitations.Table.Action.Delete.Error'),
              err
            );
          },
        }
      );
    },
    [deleteInvitation, invalidate, showError, showSuccess, t]
  );

  const copyInvitationUrlToClipboard = useCallback(
    (id: string, userEmail: string) => {
      const url = substituteRouteParams(RouteUrls.AcceptInvitation, {
        invitationId: id,
        userEmail,
      });

      navigator.clipboard.writeText(`${window.origin}${url}`);

      showSuccess(
        t('DetailCompany.Invitations.Table.Action.CopyToClipboard.Success')
      );
    },
    []
  );

  const invitationsColumns = useMemo(() => {
    const cells: ColumnDef<InvitationTableItemDto, any>[] = [
      invitationsColumnHelper.accessor((row) => row.userEmail, {
        id: 'userEmail',
        cell: (info) => info.getValue(),
        enableSorting: false,
        header: () => (
          <span>{t('DetailCompany.Invitations.Table.Column.UserEmail')}</span>
        ),
      }),
      invitationsColumnHelper.accessor((row) => row.expiration, {
        id: 'expiration',
        enableSorting: false,
        cell: (info) =>
          formatDateTime(info.getValue(), t('DateTime.Date.Format')),
        header: () => (
          <span>{t('DetailCompany.Invitations.Table.Column.Expiration')}</span>
        ),
      }),
      invitationsColumnHelper.accessor((row) => row.isAccepted, {
        id: 'isAccepted',
        cell: (info) => t(booleanToKey(info.getValue())),
        enableSorting: false,
        header: () => (
          <span>{t('DetailCompany.Invitations.Table.Column.IsAccepted')}</span>
        ),
      }),
      invitationsColumnHelper.accessor(
        (row) => {
          return (
            <Tooltip
              isTooltipHidden={!row.isAccepted}
              content={t(
                'DetailCompany.Invitations.Table.Action.DeleteAcceptedInvitation.Tooltip'
              )}
              testId="accepted-invitation-cannot-be-deleted-tooltip"
            >
              <Button
                disabled={row.isAccepted}
                title={t('DetailCompany.Invitations.Table.Action.Delete')}
                onClick={() => handleDeleteInvitation(row?.id)}
                testId="delete-invitation-button"
              />
            </Tooltip>
          );
        },
        {
          enableSorting: false,
          id: 'actions',
          cell: (info) => info.getValue(),
          header: () => (
            <span>{t('DetailCompany.Invitations.Table.Column.Actions')}</span>
          ),
        }
      ),
      invitationsColumnHelper.accessor((row) => row, {
        id: 'invitationLink',
        enableSorting: false,
        cell: (info) => {
          const { id, userEmail } = info.getValue();

          return (
            <Button
              title={t(
                'DetailCompany.Invitations.Table.Action.CopyToClipboard'
              )}
              onClick={() => copyInvitationUrlToClipboard(id, userEmail)}
            />
          );
        },
        header: () => (
          <span>
            {t('DetailCompany.Invitations.Table.Action.InvitationAccept')}
          </span>
        ),
      }),
    ];

    return cells;
  }, [t, handleDeleteInvitation]);

  const invitationsTable = useReactTable({
    data: (data?.invitations?.items as InvitationTableItemDto[]) ?? [],
    columns: invitationsColumns,
    pageCount: data?.invitations?.totalPages ?? -1,
    getCoreRowModel: getCoreRowModel(),
    manualPagination: true,
  });

  return (
    <Flex flexDirection="column" gap="4rem">
      <Heading
        title={t('DetailCompany.Invitations.Table.Header')}
        variant="h1"
      />
      <TableWrapper flexDirection="column" gap="6.5rem">
        <Flex justifyContent="space-between" alignItems="center">
          <Box width="70rem">
            <DebouncedSearchInput
              label={t('Projects.Table.Search')}
              onChange={setSearchTerm}
              testId="projectsTableSearch"
            />
          </Box>
          <AddInvitationModal companyId={companyId} />
        </Flex>
        <Table
          table={invitationsTable}
          isLoading={isLoading}
          isEmpty={data?.invitations?.items?.length === 0}
          errorInfo={{
            error,
            shortDescription: {
              title: t('Table.ShortDescriptionErrorTitle'),
              description: t('DetailCompany.Invitations.ErrorTitle'),
            },
            extendedDescription: {
              title: t('DetailCompany.Invitations.ErrorTitle'),
            },
          }}
          emptyState={{
            title: t('DetailCompany.Invitations.Table.EmptyState.Title'),
            description: t(
              'DetailCompany.Invitations.Table.EmptyState.Description'
            ),
          }}
        />
        <Pagination
          activePage={pageIndex}
          pagesCount={pageCount}
          onChange={setPageIndex}
          hideForOnlyOnePage
        />
      </TableWrapper>
    </Flex>
  );
};

export default CompanyInvitations;
