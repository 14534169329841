import { Alert, Button, Flex, Heading } from '@conteg/ui';
import { zodResolver } from '@hookform/resolvers/zod';
import { FormInput } from 'components/inputs/input';
import { FormProvider, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { z } from 'zod';

const validationSchema = z.object({
  userEmail: z
    .string({ required_error: 'Validations.Required' })
    .min(1, 'Validations.Required')
    .email('Validations.Email'),
});

export type FormInvitationInputs = {
  userEmail: string;
};

type InvitationCreateFormProps = {
  onSubmit: (values: FormInvitationInputs) => void;
  isLoading?: boolean;
  error?: string | null;
};

const InvitationCreateForm = ({
  onSubmit,
  isLoading,
  error,
}: InvitationCreateFormProps) => {
  const { t } = useTranslation();
  const formActions = useForm<FormInvitationInputs>({
    resolver: zodResolver(validationSchema),
  });

  return (
    <Flex flexDirection="column" alignItems="start" height="100%">
      <Heading title={t('InvitationForm.Form.Header')} variant="h1" />
      <FormProvider {...formActions}>
        <form onSubmit={formActions.handleSubmit(onSubmit)}>
          <Flex
            flexDirection="column"
            alignItems="start"
            width="90rem"
            marginTop="4rem"
            gap="4rem"
          >
            <FormInput
              width="10rem"
              label={t('InvitationForm.Form.UserEmail')}
              placeholder={t('InvitationForm.Form.UserEmail')}
              name="userEmail"
            />
            {error && <Alert message={error} type="error" />}

            <Flex flexDirection="row" mt="4rem">
              <Button
                disabled={isLoading}
                type="submit"
                title={t('InvitationForm.Form.SubmitButton')}
              />
            </Flex>
          </Flex>
        </form>
      </FormProvider>
    </Flex>
  );
};

export default InvitationCreateForm;
