import { Flex, styled } from '@conteg/ui';
import { Link as Anchor } from 'react-router-dom';

export const TableWrapper = styled(Flex)`
  padding: 8rem;
  background-color: ${({ theme }) => theme.colors.white};
  border-radius: 4rem;
`;

export const Link = styled(Anchor)<{ disabled?: boolean }>`
  color: ${({ theme }) => theme.colors.secondary.grey300};
  font-weight: 400;
  font-size: 3.5rem;
  line-height: 6rem;
  text-decoration: none;
`;

export const Td = styled.div`
  color: ${({ theme }) => theme.colors.secondary.grey500};
  font-weight: 600;
  font-size: 2.5rem;
  line-height: 3rem;
`;

export const Form = styled.form`
  background-color: ${({ theme }) => theme.colors.white};
  display: flex;
  flex-direction: column;
  align-items: start;
  padding: 8rem;
  border-radius: 8rem;
  gap: 4rem;
  width: 100%;
`;
