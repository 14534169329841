import {
  Alert,
  Box,
  Breadcrumb,
  Breadcrumbs,
  Flex,
  Heading,
  Loading,
  Tabs,
} from '@conteg/ui';
import UserForm from 'pages/user-detail/components/user-form';
import { UserRoles } from 'pages/user-detail/components/user-roles';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router-dom';
import { useUserQuery } from 'types/generated/graphql';
import { useAlertContext } from 'utils/alert-provider';
import { substituteRouteParams } from 'utils/routing/helpers';
import { CompanyDetailTabs, RouteUrls } from 'utils/routing/router';

const UserDetail = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { userId, subjectId } = useParams<{
    subjectId: string;
    userId: string;
  }>();

  const { alert } = useAlertContext();

  const { data: userData, isLoading: isUserLoading } = useUserQuery(
    {
      id: userId ?? '',
    },
    { enabled: !!userId }
  );

  if (isUserLoading) {
    return <Loading />;
  }

  return (
    <Flex flexDirection="column" gap="9rem">
      <Flex alignItems="center" gap="2rem">
        <Heading title={t('DetailUser.Title')} variant="h1" />
        {alert && (
          <Box width="max-content">
            <Alert message={alert?.message} type={alert.type} />
          </Box>
        )}
      </Flex>
      <Breadcrumbs
        items={[
          {
            canGoBack: true,
            label: t('Breadcrumb.Home'),
            url: RouteUrls.Companies,
          },
          {
            canGoBack: true,
            label: t('Breadcrumb.DetailCompany'),
            url: substituteRouteParams(
              `${RouteUrls.CompanyDetail}${CompanyDetailTabs.Users}`,
              {
                subjectId,
              }
            ),
          },
          {
            canGoBack: true,
            label: t('Breadcrumb.DetailUser'),
            url: substituteRouteParams(RouteUrls.UserDetail, {
              subjectId,
              userId: userId ?? '',
            }),
          },
        ]}
        navigate={(to: Breadcrumb) => navigate(to.url)}
      />

      {userData?.user && (
        <Tabs
          tabs={[
            {
              id: 'user-form',
              content: (
                <UserForm
                  defaultValues={{
                    email: userData?.user.email || '',
                    firstName: userData?.user.firstName || '',
                    phoneNumber: userData?.user.phoneNumber || '',
                    lastName: userData?.user.lastName || '',
                  }}
                />
              ),
              title: t('UserUpdateForm.UserEdit'),
            },
            {
              id: 'user-roles',
              content: <UserRoles user={userData?.user} />,
              title: t('UserRoles.Title'),
            },
          ]}
        />
      )}
    </Flex>
  );
};

export default UserDetail;
