import { useInvitationStatusQuery } from 'types/generated/graphql';

export const useInvitationStatus = (
  id: string | undefined,
  email: string | undefined
) => {
  const { data, isLoading, error } = useInvitationStatusQuery(
    { email: email as string, invitationId: id as string },
    { enabled: !!(id && email) }
  );

  return {
    status: data?.invitationStatus,
    invitationStatusError: error,
    isLoadingInvitationStatus: isLoading,
  };
};
