import { authLinks, AuthRoutes, useAuth, useUserData } from '@conteg/auth';
import { Navbar as ContegUiNavbar } from '@conteg/ui';
import { CUSTOM_AUTH_TOKEN, INSTALLATIONS_LIST_ROUTE } from 'config';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import env from 'utils/env/env';
import { openExternalLink } from 'utils/open-external-link/open-external-link';
import { substituteRouteParams } from 'utils/routing/helpers';
import { RouteUrls } from 'utils/routing/router';

export const Navbar: React.FC = () => {
  const { t } = useTranslation();
  const { signoutRedirect } = useAuth();
  const navigate = useNavigate();
  const userData = useUserData(CUSTOM_AUTH_TOKEN);

  const titles: Record<AuthRoutes, string> = {
    [AuthRoutes.CHANGE_PASSWORD]: t('ChangePassword'),
    [AuthRoutes.MY_PROFILE]: t('MyProfile'),
  };
  const authMenuLinks = authLinks.map((link) => ({
    label: titles[link.route],
    onClick: () => {
      window.open(`${env.VITE_IDENTITY_WEB_URL}${link.link}`, '_blank');
    },
  }));

  const navLinks = [
    {
      label: t('Companies.Header'),
      onClick: () => navigate(RouteUrls.Companies),
    },
    {
      label: t('RolesDocumentation.Title'),
      onClick: () => navigate(RouteUrls.RolesDocumentation),
    },
    {
      label: t('Navbar.Link.CompanyDetail'),
      onClick: () => {
        if (userData?.subjectId) {
          navigate(
            substituteRouteParams(RouteUrls.CompanyDetail, {
              subjectId: userData?.subjectId,
            })
          );
        }
      },
    },
    {
      label: t('Navbar.Link.ManageInstallations'),
      onClick: () => {
        openExternalLink(INSTALLATIONS_LIST_ROUTE);
      },
    },
  ];

  return (
    <ContegUiNavbar
      logoLink={{
        label: 'Conteg',
        onClick: () => navigate(RouteUrls.Companies),
      }}
      navLinks={navLinks}
      menuLinks={[
        ...authMenuLinks,
        {
          label: t('Navbar.Logout'),
          onClick: () => signoutRedirect(),
        },
      ]}
      user={{
        email: userData?.email || '',
        name: userData?.fullName || '',
      }}
    />
  );
};
