import { useAuth } from '@conteg/auth';
import React, { ReactNode } from 'react';
import { Navigate } from 'react-router-dom';

type PublicOnlyRouteProps = {
  authenticated: boolean;
  redirectPath?: string;
  children: ReactNode;
};

export const PublicOnlyRoute: React.FC<PublicOnlyRouteProps> = ({
  redirectPath = '/',
  children,
}) => {
  const { isAuthenticated } = useAuth();

  if (isAuthenticated) {
    return <Navigate to={redirectPath} replace />;
  }

  return <>{children}</>;
};
