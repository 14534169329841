import { Flex, styled } from '@conteg/ui';
import { Outlet } from 'react-router-dom';

const FlexLayout = styled(Flex)`
  padding: 10rem;
  flex: 1;
`;

const Layout: React.FC = () => {
  return (
    <FlexLayout flexDirection="column">
      <Outlet />
    </FlexLayout>
  );
};

export default Layout;
