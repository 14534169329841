import { EnumLike, z } from 'zod';

export const requiredString = z
  .string({ required_error: 'Validations.Required' })
  .min(1, 'Validations.Required');

export const requiredPositiveInteger = z
  .number({ required_error: 'Validations.Required' })
  .int({ message: 'ThisIsNotValidNumber' })
  .positive({ message: 'ValueMustBeGreaterThenZero' });

export const requiredSelect = z
  .string({ required_error: 'Validations.Required' })
  .min(1, 'Validations.Required');

export const requiredEnum = (enumObject: EnumLike) =>
  z.nativeEnum(enumObject, { required_error: 'Validations.Required' });

export const postalCode = z.string().regex(/^\d{5}$/, 'Validations.PostalCode');
