import { createGlobalStyle } from 'styled-components';

const GlobalStyles = createGlobalStyle`
  body {
    /* Is there way how to add it from theme? */
    background-color:  #F4F5FA;
    width: 380rem;
    max-width: 100%;
    margin: 0 auto;
  }

   ul, li {
    font-size: 3.5rem;
  }
`;

export default GlobalStyles;
