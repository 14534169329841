import {
  Box,
  Button,
  Flex,
  Heading,
  IconButton,
  Pagination,
  Table,
} from '@conteg/ui';
import {
  createColumnHelper,
  getCoreRowModel,
  useReactTable,
} from '@tanstack/react-table';
import { DebouncedSearchInput } from 'components/debounced-search-input/debounced-search-input';
import { PolicyGuardWrapper } from 'components/policy-guard/policy-guard';
import { Link, TableWrapper, Td } from 'components/styled';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import {
  CompaniesListItemFragment,
  CompanyTableItemDto,
  useCompaniesQuery,
} from 'types/generated/graphql';
import { substituteRouteParams } from 'utils/routing/helpers';
import { RouteUrls } from 'utils/routing/router';

const columnHelper = createColumnHelper<CompaniesListItemFragment>();

const pageSize = 10;

const Companies = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const [pageIndex, setPageIndex] = useState<number>(0);
  const [searchQuery, setSearchQuery] = useState<string>('');

  const { data, isLoading, error } = useCompaniesQuery({
    pageIndex,
    pageSize,
    name: searchQuery,
  });

  const tableData = data?.companies?.items ?? [];

  const pageCount = data?.companies?.totalPages ?? -1;

  useEffect(() => {
    setPageIndex(0);
  }, [searchQuery]);

  const navigateToCompanyDetail = useCallback(
    (subjectId: string) => {
      navigate(
        substituteRouteParams(RouteUrls.CompanyDetail, {
          subjectId,
        })
      );
    },
    [navigate]
  );

  const columns = useMemo(
    () => [
      columnHelper.accessor(
        (row) => {
          return (
            <Link
              to={substituteRouteParams(RouteUrls.CompanyDetail, {
                subjectId: row.subjectId,
              })}
            >
              {row.name}
            </Link>
          );
        },
        {
          id: 'name',
          cell: (info) => info.getValue(),
          header: () => <Td>{t('company_name')}</Td>,
          enableSorting: false,
        }
      ),
      columnHelper.accessor((row) => row.subjectId, {
        id: 'subjectId',
        enableSorting: false,
        cell: (info) => (
          <Flex justifyContent="flex-end">
            <IconButton
              testId="pointDetail"
              name="chevronRight"
              onClick={() => navigateToCompanyDetail(info.getValue())}
            />
          </Flex>
        ),
        header: () => <></>,
      }),
    ],
    [navigateToCompanyDetail, t]
  );

  const table = useReactTable({
    data: (tableData as CompanyTableItemDto[]) ?? [],
    columns,
    pageCount,
    getCoreRowModel: getCoreRowModel(),
    manualPagination: true,
  });

  return (
    <Flex flexDirection="column" gap="4rem">
      <Heading title={t('Companies.Header')} variant="h1" />
      <TableWrapper flexDirection="column">
        <Flex justifyContent="space-between" mb="10rem">
          <Box width="76rem">
            <DebouncedSearchInput
              label={t('Companies.Table.Search')}
              onChange={setSearchQuery}
              testId="installationsTableSearch"
            />
          </Box>
          <PolicyGuardWrapper resource="organization" action="createCompany">
            <Button
              variant="primary"
              title={t('Companies.Button.AddCompany')}
              onClick={() => navigate(RouteUrls.CreateCompany)}
              testId="create-company-btn"
            />
          </PolicyGuardWrapper>
        </Flex>
        <Table
          table={table}
          isLoading={isLoading}
          isEmpty={data?.companies.items?.length === 0}
          onRowClick={(row) => navigateToCompanyDetail(row.subjectId)}
          errorInfo={{
            error,
            shortDescription: {
              title: t('Table.ShortDescriptionErrorTitle'),
              description: t('Companies.Table.Error.Description'),
            },
            extendedDescription: {
              title: t('Companies.Table.Error.Description'),
            },
          }}
        />
        <Box padding="2rem">
          <Pagination
            hideForOnlyOnePage
            activePage={pageIndex}
            pagesCount={pageCount}
            onChange={(value) => setPageIndex(value)}
          />
        </Box>
      </TableWrapper>
    </Flex>
  );
};

export default Companies;
