import { useThemeColorValues } from '@conteg/ui';
import ReactContentLoader from 'react-content-loader';

//TODO: ADD Content loader to Common UI library
export const ContentLoader = () => {
  const theme = useThemeColorValues();
  return (
    <ReactContentLoader
      speed={3}
      width={476}
      height={124}
      viewBox="0 0 476 124"
      backgroundColor={theme.secondary.grey200}
      foregroundColor={theme.primary.blue400}
    >
      <rect x="0" y="57" rx="3" ry="3" width="410" height="6" />
      <rect x="0" y="72" rx="3" ry="3" width="380" height="6" />
      <rect x="0" y="88" rx="3" ry="3" width="178" height="6" />
    </ReactContentLoader>
  );
};
