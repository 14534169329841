import { Box, ErrorDetailInfo, styled } from '@conteg/ui';

export type PageErrorProps = {
  message: string;
  error: unknown;
  includeNavbar?: boolean;
};

export const PageError = ({ error, message }: PageErrorProps) => {
  return (
    <Container>
      <ErrorDetailInfo title={message} error={error} />
    </Container>
  );
};

const Container = styled(Box)`
  max-width: 342rem;
  background-color: ${({ theme }) => theme.colors.white};
  margin: 0 auto;
  padding: 4rem;
  width: 100%;
  border-radius: 4rem;
`;
