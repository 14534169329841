import { Alert, Box, Button, Flex, Grid, Heading } from '@conteg/ui';
import { zodResolver } from '@hookform/resolvers/zod';
import { FormInput } from 'components/inputs/input';
import { Select } from 'components/inputs/select';
import { Form } from 'components/styled';
import { FormProvider, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { Language } from 'types/generated/graphql';
import { requiredEnum, requiredString } from 'utils/validations';
import { z } from 'zod';

const validationSchema = z.object({
  name: requiredString,
  companyContact: requiredString,
  defaultLanguage: requiredEnum(Language),
  fallbackLanguage: requiredEnum(Language),
});

export type ProjectFormInputs = z.infer<typeof validationSchema>;

type ProjectFormProps = {
  projectId?: string;
  title: string;
  onSubmit: (data: ProjectFormInputs) => void;
  defaultValues?: Partial<ProjectFormInputs>;
  submitButtonLabel: string;
  onGoBack: () => void;
  hasNewProjectFields?: boolean;
  canEdit?: boolean;
};

const ProjectForm = ({
  defaultValues,
  title,
  submitButtonLabel,
  onGoBack,
  onSubmit,
  canEdit,
}: ProjectFormProps) => {
  const { t } = useTranslation();

  const formActions = useForm<ProjectFormInputs>({
    defaultValues,
    resolver: zodResolver(validationSchema),
  });

  const { handleSubmit, watch } = formActions;

  const formCompanyContact = watch('companyContact');

  const wasContactUpdated =
    defaultValues?.companyContact &&
    defaultValues.companyContact !== formCompanyContact;

  return (
    <FormProvider {...formActions}>
      <Form onSubmit={handleSubmit(onSubmit)}>
        <Flex flexDirection="row" justifyContent="space-between" width="100%">
          <Heading variant="h3" title={title} />
          <Flex flexDirection="row" gap="4rem">
            <Button
              title={t('CompanyForm.Form.Back')}
              testId="btn-go-back-to-projects"
              variant="secondary"
              onClick={onGoBack}
            />
            {canEdit && (
              <Button
                testId="submit-project-form-btn"
                title={submitButtonLabel}
                type="submit"
              />
            )}
          </Flex>
        </Flex>
        <Grid
          gridTemplateColumns="repeat(4, 1fr)"
          gridAutoRows="min-content"
          gridRowGap="6rem"
          gridColumnGap="7rem"
        >
          <FormInput
            disabled={!canEdit}
            label={t('ProjectForm.Form.Field.Name')}
            placeholder={t('ProjectForm.Form.Field.Name')}
            name="name"
          />
          <FormInput
            disabled={!canEdit}
            label={t('ProjectForm.Form.Field.CompanyContact')}
            placeholder={t('ProjectForm.Form.Field.CompanyContact')}
            name="companyContact"
          />
          <Select
            disabled={!canEdit}
            label={t('ProjectForm.Form.Field.DefaultLanguage')}
            name="defaultLanguage"
            isNullable={false}
            options={Object.values(Language).map((language) => ({
              label: t(`Language.${language}`),
              value: language,
            }))}
          />
          <Select
            disabled={!canEdit}
            label={t('ProjectForm.Form.Field.FallbackLanguage')}
            name="fallbackLanguage"
            isNullable={false}
            options={Object.values(Language).map((language) => ({
              label: t(`Language.${language}`),
              value: language,
            }))}
          />
        </Grid>
        <Box height="6rem">
          {wasContactUpdated && (
            <Alert
              message={t('ProjectForm.UpdateCompanyContactWarning')}
              type="warning"
            />
          )}
        </Box>
      </Form>
    </FormProvider>
  );
};

export default ProjectForm;
