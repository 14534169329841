/**
 * Returns the name of a GraphQL query.
 * @param query - The GraphQL query string.
 * @returns The name of the query, or null if it cannot be found.
 */
export const getQueryName = (query: string) => {
  const regex = /query\s+(\w+)\s*(\([^)]*\))?\s*{/;
  const match = regex.exec(query);
  if (match) {
    return match[1];
  }
  return null;
};
