import { Box, Button } from '@conteg/ui';
import { useRef } from 'react';
import { useTranslation } from 'react-i18next';

type FileUploadInputProps = {
  maxSizeInMb: number;
  hasImage: boolean;
  onChange: (file: File | null) => void;
};

export const FileUploadInput = ({
  onChange,
  hasImage,
}: FileUploadInputProps) => {
  const inputFileRef = useRef<HTMLInputElement>(null);
  const { t } = useTranslation();

  return (
    <Box>
      <Button
        type="button"
        title={
          hasImage
            ? t('CompanyLogo.Image.EditImage')
            : t('CompanyLogo.Image.UploadImage')
        }
        size="xs"
        onClick={() => inputFileRef.current?.click()}
      />
      <input
        type="file"
        ref={inputFileRef}
        onChange={(ev) => onChange(ev.target?.files?.[0] || null)}
        style={{ height: 0 }}
        accept=".svg"
      />
    </Box>
  );
};
