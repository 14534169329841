import env from '../env/env';

export enum NodeEnv {
  Staging = 'staging',
  Development = 'development',
  Production = 'production',
}

export const getEnvironmentMode = {
  isDevelopment: env?.NODE_ENV === NodeEnv.Development,
  isStaging: env?.NODE_ENV === NodeEnv.Staging,
  isProduction: env?.NODE_ENV === NodeEnv.Production,
};
