import { roleNames } from '@conteg/auth';
import {
  Flex,
  Heading,
  IconButton,
  Link,
  Loading,
  styled,
  Text,
  Tooltip,
} from '@conteg/ui';
import { PolicyGuardWrapper } from 'components/policy-guard/policy-guard';
import { AddUserRoleModal } from 'pages/user-detail/components/add-user-role-modal';
import { DeleteUserRoleModal } from 'pages/user-detail/components/delete-user-role-modal';
import { Fragment, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import {
  UserFragment,
  useRolesQuery,
  UserRoleFragment,
} from 'types/generated/graphql';
import { notEmpty } from 'utils/not-empty/not-empty';
import { RouteUrls } from 'utils/routing/router';

export type UserRolesProps = {
  user: UserFragment;
};

const FormWrapper = styled(Flex)`
  background-color: ${({ theme }) => theme.colors.white};
`;

const RolesContainer = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-row-gap: 2rem;
  grid-column-gap: 1rem;
  align-items: center;
`;

export const UserRoles = ({ user }: UserRolesProps) => {
  const { data } = useRolesQuery();
  const { t } = useTranslation();
  const [roleToAdd, setRoleToAdd] = useState<UserRoleFragment | null>(null);
  const [roleToDelete, setRoleToDelete] = useState<UserRoleFragment | null>(
    null
  );
  const userRoles = user.roles.filter(notEmpty);
  const navigate = useNavigate();
  const rolesToAssign =
    data?.roles
      .filter(notEmpty)
      .filter(
        (item) => user.roles?.find((role) => role.id === item.id) === undefined
      ) || [];

  if (!user || !data) {
    return <Loading />;
  }

  return (
    <FormWrapper
      alignItems="start"
      padding="8rem"
      borderRadius="8rem"
      gap="20rem"
      width="100%"
    >
      <Flex flexDirection="column" gap="4rem">
        <Heading title={t('UserRoles.Title')} variant="h2" />
        <Link
          title={t('RolesDocumentation.Title') as string}
          onClick={() => navigate(RouteUrls.RolesDocumentation)}
        />
        <Flex gap="20rem" justifyContent="space-between" width="100%">
          <Flex flexDirection="column" gap="4rem">
            <Heading title={t('UserUpdateForm.AssignedRoles')} variant="h3" />
            <RolesContainer>
              {userRoles.map((item) => (
                <Fragment key={item.id}>
                  <Text content={t(`RolesDocumentation.Role.${item.name}`)} />
                  <PolicyGuardWrapper
                    action="canEditUserRoles"
                    resource="organization"
                  >
                    <Tooltip
                      content={t('UserUpdateForm.MemberRoleCannotBeRemoved')}
                      testId="delete-role-tooltip"
                      isTooltipHidden={item.name !== roleNames.member}
                    >
                      <IconButton
                        disabled={item.name === roleNames.member}
                        name="delete"
                        onClick={() => setRoleToDelete(item)}
                      />
                    </Tooltip>
                  </PolicyGuardWrapper>
                </Fragment>
              ))}
            </RolesContainer>
          </Flex>
          <PolicyGuardWrapper action="canEditUserRoles" resource="organization">
            <Flex flexDirection="column" gap="4rem">
              <Heading title={t('UserUpdateForm.AssignNewRole')} variant="h3" />
              <RolesContainer>
                {rolesToAssign.map((item) => (
                  <Fragment key={item.id}>
                    <Text content={t(`RolesDocumentation.Role.${item.name}`)} />
                    <IconButton
                      name="plus"
                      onClick={() => setRoleToAdd(item)}
                    />
                  </Fragment>
                ))}
              </RolesContainer>
            </Flex>
          </PolicyGuardWrapper>
        </Flex>
      </Flex>
      <DeleteUserRoleModal
        userId={user.id}
        role={roleToDelete}
        onClose={() => setRoleToDelete(null)}
      />
      <AddUserRoleModal
        userId={user.id}
        role={roleToAdd}
        onClose={() => setRoleToAdd(null)}
      />
    </FormWrapper>
  );
};
