import { Rule } from 'pages/roles-documentation/types';

export const displayOnlyAllowedActions =
  (rolesFiltr: string[] | null) => (rule: Rule) => {
    return (
      !rolesFiltr ||
      rule.allowedRoles.find((allowedRole) => rolesFiltr.includes(allowedRole))
    );
  };

export const passesRoleNameSomeAction = (
  rules: Array<Rule>,
  rolesFiltr: string[]
) => {
  return rules.find((rule) =>
    rule.allowedRoles.find((allowedRole) => rolesFiltr.includes(allowedRole))
  );
};

type GroupedByResource = Record<string, Rule[]>;

export const groupRulesByResource = (rules: Array<Rule>) => {
  return rules.reduce((acc: GroupedByResource, rule: Rule) => {
    if (!acc[rule.resource]) {
      acc[rule.resource] = [];
    }
    acc[rule.resource].push(rule);
    return acc;
  }, {});
};
