import { Flex, Heading } from '@conteg/ui';
import { AlertBox } from 'components/alert-box/alert-box';

type PageHeadingProps = {
  title: string;
  variant?: 'h1' | 'h2' | 'h3' | 'h4';
};

export const PageHeading = ({ title, variant = 'h1' }: PageHeadingProps) => {
  return (
    <Flex gap="4rem" flexDirection="row">
      <Heading variant={variant} title={title} />
      <AlertBox />
    </Flex>
  );
};
