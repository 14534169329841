import { Option, Select as ContegUiSelect } from '@conteg/ui';
import { InputHTMLAttributes } from 'react';
import {
  Controller,
  FieldPath,
  FieldValues,
  useFormContext,
} from 'react-hook-form';
import { useTranslation } from 'react-i18next';

type SelectProps<T extends FieldValues> =
  InputHTMLAttributes<HTMLInputElement> & {
    name: FieldPath<T>;
    options: Option[];
    label: string;
    testId?: string;
    disabled?: boolean;
    hasAutocomplete?: boolean;
    isNullable?: boolean;
  };

export const Select = <T extends FieldValues>({
  name,
  options,
  label,
  testId,
  disabled,
  hasAutocomplete,
  isNullable = true,
}: SelectProps<T>) => {
  const { control } = useFormContext();

  const { t } = useTranslation();
  return (
    <Controller
      control={control}
      name={name}
      render={({ field, fieldState }) => {
        return (
          <ContegUiSelect
            hasAutocomplete={hasAutocomplete}
            width="100%"
            testId={testId || `textInput-${name}`}
            defaultSingleValue={field.value}
            userSingleValue={field.value}
            label={label}
            onSingleValueChange={field.onChange}
            options={options}
            error={fieldState.error?.message && t(fieldState.error?.message)}
            disabled={disabled}
            isNullable={isNullable}
          />
        );
      }}
    />
  );
};
