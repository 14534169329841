import { Input } from '@conteg/ui';
import debounce from 'lodash/debounce';
import { forwardRef } from 'react';

type DebouncedSearchInputProps = {
  onChange: (value: string) => void;
  label: string;
  testId: string;
};

const TIMING = 50;

export const DebouncedSearchInput = forwardRef<
  HTMLInputElement,
  DebouncedSearchInputProps
>(({ onChange, label, testId }: DebouncedSearchInputProps, ref) => {
  const debouncedChangeHandler = debounce((event) => {
    onChange(event.target.value.toLocaleLowerCase());
  }, TIMING);

  return (
    <Input
      ref={ref}
      testId={testId}
      search
      placeholder={label}
      label={label}
      onChange={debouncedChangeHandler}
    />
  );
});

// The TS error is often caused when using forwardRefs in React. Setting the displayName property on the component to fix the "Component definition is missing display name"

DebouncedSearchInput.displayName = 'DebouncedSearchInput';
