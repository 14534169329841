import { useAuth, useAutoInitLogin } from '@conteg/auth';
import { Alert, Button, Flex, Loading, Text } from '@conteg/ui';
import { CUSTOM_AUTH_TOKEN } from 'config';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation, useNavigate } from 'react-router-dom';
import { RouteUrls } from 'utils/routing/router';

export type LoginLocationState = {
  onSuccessPath: string;
};

const isLoginLocationState = (state: unknown): state is LoginLocationState =>
  (state as LoginLocationState)?.onSuccessPath !== undefined;

const Login: React.FC = () => {
  const location = useLocation();

  const loginSuccessRedirect =
    (isLoginLocationState(location.state) && location.state.onSuccessPath) ||
    RouteUrls.Companies;

  const { t } = useTranslation();
  const navigate = useNavigate();
  const { signinRedirect } = useAuth();

  const { isLoading, error } = useAutoInitLogin(
    loginSuccessRedirect,
    navigate,
    !!CUSTOM_AUTH_TOKEN
  );

  if (isLoading) {
    return (
      <Flex flexDirection="column" alignItems="center">
        <Loading />
      </Flex>
    );
  }

  if (error) {
    return (
      <Flex flexDirection="column" alignItems="center">
        <Alert message={t('Login.Error')} type="error" />
        <Button type="button" onClick={signinRedirect} title={t('login')} />
      </Flex>
    );
  }

  return (
    <Flex flexDirection="column" alignItems="center">
      <Text content={t('Login.Info')} />
      <Button type="button" onClick={signinRedirect} title={t('login')} />
    </Flex>
  );
};

export default Login;
