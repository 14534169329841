import { Input, InputProps, InputTypes } from '@conteg/ui';
import { FieldPath, FieldValues, useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { isInCypress } from 'utils/is-in-cypress/is-in-cypress';

type FormInputProps<T extends FieldValues> = InputProps & {
  name: FieldPath<T>;
};

export const FormInput = <T extends FieldValues>({
  name,
  label,
  placeholder,
  testId,
  type = InputTypes.TEXT,
  ...rest
}: FormInputProps<T>) => {
  const { register, formState, getFieldState } = useFormContext();
  const { t } = useTranslation();

  const { error } = getFieldState(name, formState);

  return (
    <Input
      showTimePickerOnFocus={!isInCypress()}
      testId={testId}
      {...register(name, {
        setValueAs: (value) => {
          if (type === InputTypes.NUMBER) {
            // workaround: empty values for optional number
            // https://github.com/react-hook-form/react-hook-form/discussions/6980
            return value === '' ? undefined : parseFloat(value);
          }
          return value;
        },
      })}
      type={type}
      label={label}
      placeholder={placeholder}
      error={error?.message && (t(error.message) as string)}
      {...rest}
    />
  );
};
