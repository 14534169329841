import env from 'utils/env/env';
import { isInCypress } from 'utils/is-in-cypress/is-in-cypress';

export const alertTimeoutInMillis = 5000;

export const CYPRESS_ACCESS_TOKEN =
  'eyJhbGciOiJSUzI1NiIsImtpZCI6IjYwNTM4REUyQ0E2MjAxRDdFNUZCRkQ5ODQ5QTFCNDhDIiwidHlwIjoiYXQrand0In0.eyJpc3MiOiJodHRwczovL2FwaS5pZGVudGl0eS5kZXYuMnhsb2NrZXJzLmV1IiwibmJmIjoxNzA1NDg1MDI4LCJpYXQiOjE3MDU0ODUwMjgsImV4cCI6MTcwNTQ4ODYyOCwiYXVkIjpbIklkZW50aXR5QXBpIiwiUmVzZXJ2YXRpb25Mb2dpc3RpY3NBcGkiLCJDb21wYW55QXBpIiwiRGV2aWNlQXBpIiwiR2F0ZXdheUFwaSIsIkF1ZGl0QXBpIiwiSGFyZHdhcmVBcGkiLCJJbnRlZ3JhdGlvbkFwaSIsIkNlc2thUG9zdGFBcGkiLCJDZXNrYVNwb3JpdGVsbmFBcGkiLCJFdmVudEJyb2tlciIsIldvcmtmbG93QXBpIiwiU3luY2hyb25pemF0aW9uQXBpIiwiQ29tbXVuaWNhdGlvbkFwaSIsIkNvbW1vbkFwaSJdLCJzY29wZSI6WyJBcGkuYWNjZXNzIiwiSW50ZWdyYXRpb25BcGkucmVhZCIsIkludGVncmF0aW9uQXBpLndyaXRlIiwib2ZmbGluZV9hY2Nlc3MiXSwiYW1yIjpbInB3ZCJdLCJjbGllbnRfaWQiOiJhbnlfbW9iX3dlYl9hcHBfaW50ZXJhY3RpdmVfY2xpZW50Iiwic3ViIjoiMjI1N2I5ZTctNjEwYy00ZmFjLTgzNjktNjRmYTBjNTMzOTFiIiwiYXV0aF90aW1lIjoxNzA1NDg1MDI3LCJpZHAiOiJsb2NhbCIsIkFwcGxpY2F0aW9uSWQiOiIiLCJFbWFpbCI6Im1pY2hhbC5rcmF1c2tvcGYudmx0cm5AZ21haWwuY29tIiwiRmlyc3ROYW1lIjoiTWljaGFsIiwiTGFzdE5hbWUiOiJLcmF1c2tvcGYiLCJJc3N1ZURhdGUiOiIwMS8xNy8yMDI0IDA5OjUwOjI4ICswMDowMCIsIlN1YmplY3RJZCI6ImRjZDNmNjJiLWQxY2UtZmEwOS1lMjY0LTMwM2YwNzdkOTUyMSIsIlVzZXJJZCI6IjIyNTdiOWU3LTYxMGMtNGZhYy04MzY5LTY0ZmEwYzUzMzkxYiIsIkFwcGxpY2F0aW9uSW5zdGFuY2VJZCI6IiIsIlVzZXJUeXBlIjoiUmVnaXN0ZXJlZCIsIklzUHJvdmlkZXIiOiJUcnVlIiwiUm9sZU5hbWVzIjpbIiIsIkFkbWluIl0sInNpZCI6IkVDRTJDOTc3MUQzNDM4RTQxQzYzQ0EzMjM5QTcxQzE3IiwianRpIjoiMzE5QjM1QTk3OUUwMzMwNUJBMzgwMjZENDg1Nzg1NUIifQ.fVL3OuSL49PqbSXSmOvLk3jXP_vR5Du2mN4_bzH6iq4jTLdx8B4rsPJN3QPm0GxjpDZCB2coIWQQBbsd3M8wg53_2GfCs_Q4wP1rnchjT3Q4dRKEwBfPi1OFeaIxXbMToSiD1DycapubcEpRk-yEeH9DpO3t3ftdrDRqWWAd6fSyUnX40IuAddmID7hGB-L4_-VUoFkTt2iiGXRFgiyDi2dy339tvywHPEFyBOi-653bI1ticj8qBvbKNDEFwIO3RdnYknqZ0RzDsBjTZKfMtJ8InSMynF9jZRSwItDzp6nSXScJv83EtilsOl27TIVQzQGuGYjpgA2cUA6u3gdcvg';

export const CUSTOM_AUTH_TOKEN = isInCypress()
  ? CYPRESS_ACCESS_TOKEN
  : import.meta.env.VITE_CUSTOM_AUTH_TOKEN;

export const AUTH_CLIENT_ID = 'any_mob_web_app_interactive_client';

export const INSTALLATIONS_LIST_ROUTE = `${env.VITE_DEVICE_WEB_URL}/installations`;

export const FINISH_REGISTRATION_ROUTE = `${env.VITE_IDENTITY_WEB_URL}/register-by-email-invitation/:token/:userEmail/?redirectUrl=${window.origin}`;

//If you edit this change in translation as well IntegrationProfile.Form.MaxPeriodDays.MaxValueError
export const INTEGRATION_PROFILE_MAX_RESERVATION_DAYS = 7299;

export const OPA_POLICIES_NAME = 'conteg';

export const EMAILS_FOR_ACCESS_CREDENTIALS_REGENERATE = [
  'adrian.zak@oxpoint.cz',
  'vladislav.kral@conteg.cz',
  'conteg.user@valatron.com',
];
