import { AuthConfig, AuthProvider } from '@conteg/auth';
import { AUTH_CLIENT_ID } from 'config';
import { ReactNode } from 'react';
import env from 'utils/env/env';

export const AuthContextProvider = ({ children }: { children: ReactNode }) => {
  const authConfig: AuthConfig = {
    scope: 'Api.access',
    redirectUri: window.location.origin,
    postLogoutRedirectUri: window.location.origin,
    subjectId: env.VITE_AUTH_SUBJECT_ID,
    applicationInstanceId: env.VITE_AUTH_APP_INSTANCE_ID,
    authority: env.VITE_IDENTITY_API_URL,
    clientId: AUTH_CLIENT_ID,
    issuer: 'Conteg_X2d',
    endSessionEndpoint: `${env.VITE_IDENTITY_WEB_URL}/logout`,
  };

  return <AuthProvider config={authConfig}>{children}</AuthProvider>;
};
