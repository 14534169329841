type Params = {
  userId?: string;
  installationId?: string;
  projectId?: string;
  token?: string;
  invitationId?: string;
  userEmail?: string;
  subjectId?: string;
  integrationProfileId?: string;
  accessRuleId?: string;
  companyId?: string;
};

/**
 * Substitute route parameters in a given route string.
 * @param route - The route string with placeholders.
 * @param params - An object containing parameter key-value pairs.
 * @returns The route string with placeholders replaced by corresponding parameter values.
 */
export const substituteRouteParams = (route: string, params: Params) => {
  let replacedRoute = route;

  Object.entries(params).forEach(([key, value]) => {
    replacedRoute = replacedRoute.replace(`:${key}`, value);
  });

  replacedRoute = replacedRoute.replace('*', '');

  return replacedRoute;
};
