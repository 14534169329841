import { styled } from '@conteg/ui';

type ImageContainerProps = {
  title: string;
  imageUrl: string | undefined | null;
};

export const ImageContainer = ({ title, imageUrl }: ImageContainerProps) => {
  return (
    <Container>
      {title}
      {imageUrl && <LogoImage src={imageUrl} />}
    </Container>
  );
};

export const Container = styled.div`
  width: 88rem;
  height: 35rem;
  font-size: 4.5rem;
  line-height: 11rem;
  background: rgb(16, 15, 36);
  font-weight: 700;
  text-align: center;
  color: rgb(255, 255, 255);
  border-radius: 12px;
  display: flex;
  align-items: center;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  gap: 0rem;
  padding: 0;
`;

const LogoImage = styled.img`
  height: 16rem;
  width: 67%;
`;
