import { TabItem, Tabs } from '@conteg/ui';
import {
  Navigate,
  Route,
  Routes,
  useLocation,
  useNavigate,
} from 'react-router-dom';

type ReactRouterTab = Omit<TabItem, 'id'> & {
  element: React.ReactNode;
  path: string;
  hidden?: boolean;
};

type ReactRouterTabsProps = {
  tabs: ReactRouterTab[];
};

export const ReactRouterTabs = ({ tabs }: ReactRouterTabsProps) => {
  const navigate = useNavigate();
  const location = useLocation();

  const locationTab = location.pathname.split('/').pop();

  const selectedIndex = tabs.findIndex((tab) => locationTab === tab.path);

  return (
    <>
      <Tabs
        selectedIndex={selectedIndex || 0}
        tabs={tabs
          .filter((tab) => !tab.hidden)
          .map((tab) => ({ ...tab, id: tab.path }))}
        defaultIndex={selectedIndex || 0}
        onClick={(tab) => navigate((tab as unknown as ReactRouterTab).path)}
      />
      <Routes>
        {tabs.map((tab) => (
          <Route key={tab.path} element={tab.element} path={`/${tab.path}`} />
        ))}
        <Route path="*" element={<Navigate to={tabs[0].path} />} />
      </Routes>
    </>
  );
};
