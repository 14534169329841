import { usePolicyEvaluate } from 'components/policy-loader/policy-loader';
import { ReactNode } from 'react';
import { PolicyAction, PolicyResource } from 'types/policies-types';

type PolicyGuardProps = {
  action: PolicyAction;
  resource: PolicyResource;
  children: ReactNode;
};

export const PolicyGuardWrapper = ({
  action,
  resource,
  children,
}: PolicyGuardProps) => {
  const passes = usePolicyEvaluate(action, resource);

  if (!passes) {
    return null;
  }

  return <>{children}</>;
};
