import { Flex, Grid, Heading, styled } from '@conteg/ui';
import { FormInput } from 'components/inputs/input';
import { FormProvider, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

type UserFormInputs = {
  email: string;
  firstName: string;
  lastName: string;
  phoneNumber: string;
};

type UserFormProps = {
  userId?: string;
  defaultValues: UserFormInputs;
};

const FormWrapper = styled(Flex)`
  background-color: ${({ theme }) => theme.colors.white};
`;

const UserForm = ({ defaultValues }: UserFormProps) => {
  const { t } = useTranslation();

  const formActions = useForm<UserFormInputs>({ defaultValues });

  return (
    <FormWrapper
      alignItems="start"
      padding="8rem"
      borderRadius="8rem"
      gap="20rem"
      width="100%"
    >
      <FormProvider {...formActions}>
        <Flex flexDirection="column" alignItems="start" gap="4rem">
          <Heading title={t('UserUpdateForm.UserEdit')} variant="h2" />
          <form onSubmit={formActions.handleSubmit(() => null)}>
            <Grid
              gridTemplateColumns="repeat(2, 1fr)"
              gridAutoRows="min-content"
              gridRowGap="6rem"
              gridColumnGap="7rem"
            >
              <FormInput
                name="email"
                placeholder={t('UserUpdateForm.Form.Email')}
                label={t('UserUpdateForm.Form.Email')}
                disabled
              />
              <FormInput
                name="firstName"
                placeholder={t('UserUpdateForm.Form.FirstName')}
                label={t('UserUpdateForm.Form.FirstName')}
                disabled
              />
              <FormInput
                name="lastName"
                placeholder={t('UserUpdateForm.Form.LastName')}
                label={t('UserUpdateForm.Form.LastName')}
                disabled
              />
              <FormInput
                name="phoneNumber"
                placeholder={t('UserUpdateForm.Form.PhoneNumber')}
                label={t('UserUpdateForm.Form.PhoneNumber')}
                disabled
              />
            </Grid>
          </form>
        </Flex>
      </FormProvider>
    </FormWrapper>
  );
};

export default UserForm;
