import { Alert, Box, Button, Flex, Grid, styled } from '@conteg/ui';
import { zodResolver } from '@hookform/resolvers/zod';
import { Checkbox } from 'components/inputs/checkbox';
import { FormInput } from 'components/inputs/input';
import { CompanyLogo } from 'pages/companies/company/components/company-logo/company-logo';
import { FormProvider, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { CompanyType } from 'types/generated/graphql';
import { z } from 'zod';

export type CompanyFormInputs = {
  name: string;
  city: string;
  country: string;
  postalCode: string;
  street: string;
  businessTypes: string[];
  companyType: CompanyType | false;
  ico: string;
  dic: string;
};

type CompanyFormProps = {
  companyId?: string;
  companyLogoUrl?: string | null;
  title: string;
  onSubmit: (data: CompanyFormInputs) => void;
  defaultValues?: Partial<CompanyFormInputs>;
  submitButtonLabel: string;
  onGoBack: () => void;
  hasNewCompanyFields?: boolean;
  validationSchema: z.Schema;
  shouldEnableEdit?: boolean;
};

const FormWrapper = styled(Flex)`
  background-color: ${({ theme }) => theme.colors.white};
`;

const CompanyForm = ({
  defaultValues,
  submitButtonLabel,
  onSubmit,
  hasNewCompanyFields,
  validationSchema,
  shouldEnableEdit,
  companyId,
  companyLogoUrl,
}: CompanyFormProps) => {
  const { t } = useTranslation();

  const formActions = useForm<CompanyFormInputs>({
    defaultValues,
    resolver: zodResolver(validationSchema),
  });

  const { handleSubmit, watch } = formActions;

  const showWarning = watch('companyType');

  return (
    <FormProvider {...formActions}>
      <form onSubmit={handleSubmit(onSubmit)}>
        <FormWrapper
          flexDirection="column"
          alignItems="start"
          padding="8rem"
          borderRadius="8rem"
          gap="4rem"
        >
          <Flex flexDirection="row" justifyContent="flex-end" width="100%">
            <Flex flexDirection="row" gap="4rem">
              {shouldEnableEdit && (
                <Button
                  testId="submit-create-form-btn"
                  title={submitButtonLabel}
                  type="submit"
                />
              )}
            </Flex>
          </Flex>
          <Box maxWidth="100%" width="220rem" margin="0 auto">
            <Grid
              marginBottom="7rem"
              gridTemplateColumns="repeat(2, 1fr)"
              gridRowGap="10rem"
              gridColumnGap="7rem"
            >
              <FormInput
                label={t('CompanyForm.Form.Name')}
                placeholder={t('CompanyForm.Form.Name')}
                name="name"
                disabled={!shouldEnableEdit}
              />

              <FormInput
                label={t('CompanyForm.Form.City')}
                placeholder={t('CompanyForm.Form.City')}
                name="city"
                disabled={!shouldEnableEdit}
              />
              <Flex gap="7rem" flexDirection="column">
                <FormInput
                  name="country"
                  label={t('CompanyForm.Form.Country')}
                  placeholder={t('CompanyForm.Form.Country')}
                  disabled={!shouldEnableEdit}
                />
                <FormInput
                  label={t('CompanyForm.Form.PostalCode')}
                  placeholder={t('CompanyForm.Form.PostalCode')}
                  name="postalCode"
                  disabled={!shouldEnableEdit}
                />
                <FormInput
                  label={t('CompanyForm.Form.Street')}
                  placeholder={t('CompanyForm.Form.Street')}
                  name="street"
                  disabled={!shouldEnableEdit}
                />
              </Flex>
              <Flex gap="7rem" flexDirection="column">
                <FormInput
                  label={t('CompanyForm.Form.Ico')}
                  placeholder={t('CompanyForm.Form.Ico')}
                  name="ico"
                  disabled={!shouldEnableEdit}
                />
                <FormInput
                  label={t('CompanyForm.Form.Dic')}
                  placeholder={t('CompanyForm.Form.Dic')}
                  name="dic"
                  disabled={!shouldEnableEdit}
                />
              </Flex>
              {companyId && (
                <Box>
                  <CompanyLogo
                    shouldEnableEdit={!!shouldEnableEdit}
                    companyId={companyId}
                    imageUrl={companyLogoUrl}
                    companyTitle={defaultValues?.name || ''}
                  />
                </Box>
              )}
            </Grid>
            {hasNewCompanyFields && (
              <Flex flexDirection="column" gap="4rem">
                <Checkbox<CompanyFormInputs>
                  name="companyType"
                  label={t('CompanyForm.Form.CompanyType')}
                  testId="checkbox-companyType"
                />
                {showWarning && (
                  <Alert
                    message={t('CompanyForm.Form.CompanyType.Alert')}
                    type="warning"
                    testId="CompanyForm.Form.CompanyType.Alert"
                  />
                )}
              </Flex>
            )}
          </Box>
        </FormWrapper>
      </form>
    </FormProvider>
  );
};

export default CompanyForm;
