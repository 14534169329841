import { Checkbox as ContegUiCheckbox } from '@conteg/ui';
import {
  Controller,
  FieldPath,
  FieldValues,
  useFormContext,
} from 'react-hook-form';
import { useTranslation } from 'react-i18next';

type ContegUiCheckboxProps<T extends FieldValues> = {
  name: FieldPath<T>;
  testId: string;
  label: string;
  disabled?: boolean;
};

export const Checkbox = <T extends FieldValues>({
  name,
  testId,
  label,
  disabled,
}: ContegUiCheckboxProps<T>) => {
  const { control } = useFormContext();

  const { t } = useTranslation();
  return (
    <Controller
      control={control}
      name={name}
      render={({ field, fieldState }) => (
        <ContegUiCheckbox
          testId={testId || `checkbox-${name}`}
          defaultValue={field.value}
          label={label}
          onChange={field.onChange}
          error={
            (fieldState.error?.message && t(fieldState.error?.message)) || ''
          }
          disabled={disabled}
        />
      )}
    />
  );
};
