import { Alert, Breadcrumb, Breadcrumbs, Flex, Loading } from '@conteg/ui';
import { PageError } from 'components/page-error/page-error';
import { PageHeading } from 'components/page-heading/page-heading';
import { usePolicyEvaluate } from 'components/policy-loader/policy-loader';
import { ReactRouterTabs } from 'components/react-router-tabs/react-router-tabs';
import { CompanyAddressDetail } from 'pages/companies/company/components/company-address-detail';
import CompanyInvitations from 'pages/companies/company/components/company-invitations';
import CompanyUsers from 'pages/companies/company/components/company-users';
import Projects from 'pages/projects/projects/projects';
import ContentLoader from 'react-content-loader';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router-dom';
import { useCompanyQuery } from 'types/generated/graphql';
import { substituteRouteParams } from 'utils/routing/helpers';
import { CompanyDetailTabs, RouteUrls } from 'utils/routing/router';

const CompanyDetail = () => {
  const { t } = useTranslation();
  const { subjectId } = useParams<{ subjectId: string }>();
  const navigate = useNavigate();

  const canManageInvitations = usePolicyEvaluate(
    'canManageInvitations',
    'organization'
  );

  const { data, isLoading, error } = useCompanyQuery(
    {
      subjectId: subjectId ?? '',
    },
    { enabled: !!subjectId }
  );

  if (isLoading) {
    return <Loading />;
  }

  if (error) {
    return <PageError message={t('DetailCompany.Error.Title')} error={error} />;
  }

  if (!data?.company) {
    return (
      <Alert message={t('DetailCompany.Error.MissingData')} type="error" />
    );
  }

  const companyId = data.company.id;
  const companyName = data.company.name;

  return (
    <Flex flexDirection="column">
      <Flex flexDirection="column" gap="6.25rem">
        <Flex flexDirection="column" gap="9rem">
          <Breadcrumbs
            items={[
              {
                canGoBack: true,
                label: t('Breadcrumb.Home'),
                url: RouteUrls.Companies,
              },
              {
                canGoBack: true,
                label: t('Breadcrumb.DetailCompany'),
                url: substituteRouteParams(
                  `${RouteUrls.CompanyDetail}/projects`,
                  {
                    subjectId: companyId ?? '',
                  }
                ),
              },
            ]}
            navigate={(to: Breadcrumb) => navigate(to.url)}
          />
          {isLoading ? (
            <ContentLoader />
          ) : (
            <PageHeading title={companyName ?? t('DetailCompany.Header')} />
          )}
        </Flex>
        <ReactRouterTabs
          tabs={[
            {
              title: t('DetailCompany.Tab.Title.Contact'),
              element: (
                <CompanyAddressDetail
                  company={data.company}
                  companyId={companyId}
                />
              ),
              path: CompanyDetailTabs.Contact,
            },
            {
              title: t('DetailCompany.Tab.Title.Users'),
              element: (
                <CompanyUsers
                  companyId={companyId}
                  subjectId={subjectId as string}
                />
              ),
              path: CompanyDetailTabs.Users,
            },
            {
              title: t('DetailCompany.Tab.Title.Invitations'),
              disabled: !companyId,
              element: <CompanyInvitations companyId={companyId} />,
              hidden: !canManageInvitations,
              path: CompanyDetailTabs.Invitations,
            },
            {
              title: t('DetailCompany.Tab.Title.Projects'),
              disabled: !companyId,
              element: <Projects />,
              path: CompanyDetailTabs.Projects,
            },
          ].filter((tab) => !tab.hidden)}
        />
      </Flex>
    </Flex>
  );
};

export default CompanyDetail;
