import { showAlertModalError } from '@conteg/ui';
import { useCanEditOrganization } from 'hooks/use-can-edit-organization';
import { useInvalidateQueries } from 'hooks/use-invalidate-query';
import CompanyForm, {
  CompanyFormInputs,
} from 'pages/companies/company/components/company-form';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import {
  CompanyDetailFragment,
  CompanyDocument,
  useUpdateCompanyMutation,
} from 'types/generated/graphql';
import { useAlertContext } from 'utils/alert-provider';
import { RouteUrls } from 'utils/routing/router';
import { postalCode } from 'utils/validations';
import { dic, ico, requiredString } from 'utils/validations/validations';
import { z } from 'zod';

type CompanyAddressDetailProps = {
  companyId: string;
  company: CompanyDetailFragment;
};

const validationSchema = z.object({
  name: requiredString,
  city: requiredString,
  country: requiredString,
  postalCode: postalCode,
  street: requiredString,
  ico: ico,
  dic: dic,
});

export const CompanyAddressDetail = ({
  companyId,
  company,
}: CompanyAddressDetailProps) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { showSuccess } = useAlertContext();
  const invalidate = useInvalidateQueries();
  const canEditOrganization = useCanEditOrganization();

  const { mutateAsync: updateCompany } = useUpdateCompanyMutation();

  const handleSubmit = async (formData: CompanyFormInputs) => {
    await updateCompany(
      {
        id: companyId,
        name: formData.name,
        city: formData.city,
        country: formData.country,
        postalCode: formData.postalCode,
        street: formData.street,
        dic: formData.dic,
        ico: formData.ico,
      },
      {
        onSuccess: () => {
          showSuccess(t('CompanyUpdateForm.Form.Submit.Success'));
          invalidate([CompanyDocument]);
        },
        onError: (error) =>
          showAlertModalError(t('CompanyUpdateForm.Form.Submit.Error'), error),
      }
    );
  };

  return (
    <CompanyForm
      companyId={companyId}
      companyLogoUrl={
        company.logoUrl
          ? `${company.logoUrl}?updatedAt=${company.logoUrlUpdatedAt}`
          : null
      }
      shouldEnableEdit={canEditOrganization}
      title={t('CompanyUpdateForm.Form.Header')}
      onSubmit={handleSubmit}
      submitButtonLabel={t('CompanyUpdateForm.Form.SubmitButton')}
      defaultValues={{
        name: company.name ?? '',
        city: company.address?.city ?? '',
        country: company.address?.country ?? '',
        postalCode: company.address?.postalCode ?? '',
        street: company.address?.street ?? '',
        dic: company.dic ?? '',
        ico: company.ico ?? '',
      }}
      onGoBack={() => navigate(RouteUrls.Companies)}
      validationSchema={validationSchema}
    />
  );
};
