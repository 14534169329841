import { z } from 'zod';

export const requiredString = z
  .string({ required_error: 'Validations.Required' })
  .min(1, 'Validations.Required');

export const requiredSelect = z
  .string({ required_error: 'Validations.Required' })
  .min(1, 'Validations.Required');

export const ico = z
  .string()
  .max(15, 'CompanyForm.Form.Ico.Validation')
  .optional();

export const dic = z
  .string()
  .max(15, 'CompanyForm.Form.Dic.Validation')
  .optional();
