import { Box, Flex, Heading, Pagination, Table } from '@conteg/ui';
import {
  createColumnHelper,
  getCoreRowModel,
  useReactTable,
} from '@tanstack/react-table';
import NotFound from 'components/errors/not-found';
import { TableWrapper } from 'components/styled';
import { useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { useCompanyUsersQuery, UserDto } from 'types/generated/graphql';
import { substituteRouteParams } from 'utils/routing/helpers';
import { RouteUrls } from 'utils/routing/router';

const membersColumnHelper = createColumnHelper<UserDto>();

const pageSize = 10;

type CompanyUsersProps = {
  companyId: string;
  subjectId: string;
};

const CompanyUsers = ({ companyId, subjectId }: CompanyUsersProps) => {
  const { t } = useTranslation();

  const [pageIndex, setPageIndex] = useState<number>(0);
  const navigate = useNavigate();
  const { data, isLoading, error } = useCompanyUsersQuery({
    companyId,
    pageIndex,
    pageSize,
  });

  const membersTableColumns = useMemo(
    () => [
      membersColumnHelper.accessor(
        (row) => {
          if (!row.firstName || !row.lastName) {
            return null;
          }
          return (
            <div>
              {row.firstName} {row.lastName}
            </div>
          );
        },
        {
          enableSorting: false,
          id: 'name',
          cell: (info) => info.getValue(),
          header: () => t('DetailCompany.CompanyMembers.Table.Column.Name'),
        }
      ),
      membersColumnHelper.accessor((row) => row.email, {
        enableSorting: false,
        id: 'email',
        cell: (info) => info.getValue(),
        header: () => t('DetailCompany.CompanyMembers.Table.Column.Email'),
      }),
      membersColumnHelper.accessor((row) => row.phoneNumber, {
        enableSorting: false,
        id: 'phoneNumber',
        cell: (info) => info.getValue(),
        header: () =>
          t('DetailCompany.CompanyMembers.Table.Column.PhoneNumber'),
      }),
      membersColumnHelper.accessor((row) => row.roles, {
        enableSorting: false,
        id: 'roles',
        cell: (info) => {
          const roles = info.getValue();

          return roles
            .map((role) => t(`RolesDocumentation.Role.${role.name}`))
            .join(', ');
        },
        header: () => t('DetailCompany.CompanyMembers.Table.Column.UserRoles'),
      }),
    ],
    [t]
  );

  const companyUsersTable = useReactTable({
    data: (data?.company.users.items as UserDto[]) ?? [],
    columns: membersTableColumns,
    getCoreRowModel: getCoreRowModel(),
    manualPagination: true,
  });

  if (!companyId) return <NotFound />;

  return (
    <>
      <TableWrapper flexDirection="column" gap="6.5rem">
        <Flex justifyContent="space-between">
          <Heading variant="h3" title={t('DetailCompany.Users.Table.Header')} />
        </Flex>
        <Table
          isEmpty={data?.company?.users?.items?.length === 0}
          table={companyUsersTable}
          isLoading={isLoading}
          errorInfo={{
            error,
            shortDescription: {
              title: t('Table.ShortDescriptionErrorTitle'),
              description: t('DetailCompany.Users.Error.Title'),
            },
            extendedDescription: {
              title: t('DetailCompany.Users.Error.Title'),
            },
          }}
          emptyState={{
            title: t('DetailCompany.Users.Table.EmptyState.Title'),
            description: t('DetailCompany.Users.Table.EmptyState.Description'),
          }}
          onRowClick={(row) => {
            navigate(
              substituteRouteParams(RouteUrls.UserDetail, {
                subjectId,
                userId: row.id,
              })
            );
          }}
        />
        <Box padding="2rem">
          <Pagination
            hideForOnlyOnePage
            activePage={pageIndex}
            pagesCount={data?.company?.users.totalPages ?? -1}
            onChange={setPageIndex}
          />
        </Box>
      </TableWrapper>
    </>
  );
};

export default CompanyUsers;
