import { useAuth } from '@conteg/auth';
import { PolicyLoader } from 'components/policy-loader/policy-loader';
import { CUSTOM_AUTH_TOKEN } from 'config';
import { LoginLocationState } from 'pages/login/login';
import React, { ReactNode } from 'react';
import { Navigate } from 'react-router-dom';

type ProtectedRouteProps = {
  authenticated: boolean;
  redirectPath?: string;
  children: ReactNode;
  path: string;
};

export const ProtectedRoute: React.FC<ProtectedRouteProps> = ({
  redirectPath = '/login',
  children,
  path,
}) => {
  const { isAuthenticated } = useAuth();

  if (!isAuthenticated && !CUSTOM_AUTH_TOKEN) {
    return (
      <Navigate
        to={redirectPath}
        state={{ onSuccessPath: path } as LoginLocationState}
        replace
      />
    );
  }

  return <PolicyLoader>{children}</PolicyLoader>;
};
