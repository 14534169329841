import { Button, Modal, showAlertModalError } from '@conteg/ui';
import InvitationCreateForm, {
  FormInvitationInputs,
} from 'components/add-invitation-modal/components/invitation-create-form';
import { useInvalidateQueries } from 'hooks/use-invalidate-query';
import { useState } from 'react';
import { SubmitHandler } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import {
  InvitationsDocument,
  useCreateInvitationMutation,
} from 'types/generated/graphql';
import { useAlertContext } from 'utils/alert-provider';

type AddInvitationModalProps = {
  companyId: string;
};

const AddInvitationModal: React.FC<AddInvitationModalProps> = ({
  companyId,
}: AddInvitationModalProps) => {
  const { mutateAsync } = useCreateInvitationMutation();

  const [isOpen, setIsOpen] = useState(false);

  const { t } = useTranslation();
  const { showSuccess } = useAlertContext();

  const invalidate = useInvalidateQueries();

  const onSubmit: SubmitHandler<FormInvitationInputs> = async (formData) => {
    await mutateAsync(
      {
        companyId,
        userEmail: formData.userEmail,
      },
      {
        onSuccess: () => {
          showSuccess(t('DetailCompany.Button.AddInvitation.Success'));
          invalidate([InvitationsDocument]);
          setIsOpen(false);
        },
        onError: (error) => {
          showAlertModalError(
            t('DetailCompany.Button.AddInvitation.Error'),
            error
          );
        },
      }
    );
  };

  return (
    <>
      <Modal
        size="l"
        isOpen={isOpen}
        showCloseButton
        isSideModal
        onClose={() => setIsOpen(false)}
      >
        <InvitationCreateForm onSubmit={onSubmit} />
      </Modal>
      <Button
        variant="primary"
        title={t('DetailCompany.Button.CreateInvitation')}
        testId="create-user-btn"
        onClick={() => setIsOpen(true)}
      />
    </>
  );
};

export default AddInvitationModal;
