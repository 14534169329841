import { ThemeProvider } from '@conteg/ui';
import App from 'app';
import React from 'react';
import ReactDOM from 'react-dom/client';
import { BrowserRouter } from 'react-router-dom';
import AlertProvider from 'utils/alert-provider';
import { AuthContextProvider } from 'utils/auth/auth';
import QueryClientProvider from 'utils/gql/query-client-provider';
import GlobalStyles from 'utils/styles/global-styles';
import { theme } from 'utils/theme/theme';

ReactDOM.createRoot(document.getElementById('root') as HTMLElement).render(
  <React.StrictMode>
    <QueryClientProvider>
      <BrowserRouter>
        <AuthContextProvider>
          <ThemeProvider theme={theme} shouldLoadFontFiles={false}>
            <GlobalStyles />
            <AlertProvider>
              <App />
            </AlertProvider>
          </ThemeProvider>
        </AuthContextProvider>
      </BrowserRouter>
    </QueryClientProvider>
  </React.StrictMode>
);
