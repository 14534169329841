import { Alert, Box } from '@conteg/ui';
import { useAlertContext } from 'utils/alert-provider';

export const AlertBox = () => {
  const { alert } = useAlertContext();

  if (!alert) {
    return null;
  }
  return (
    <Box width="max-content">
      <Alert {...alert} />
    </Box>
  );
};
