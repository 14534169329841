import { Alert, Box, Heading, Loading, styled } from '@conteg/ui';
import { FINISH_REGISTRATION_ROUTE } from 'config';
import { useAcceptInviteMutation } from 'pages/accept-invitation/use-accept-invitation';
import { useInvitationStatus } from 'pages/accept-invitation/use-invitation-status';
import { useEffect, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { InvitationStatus } from 'types/generated/graphql';
import { openExternalLink } from 'utils/open-external-link/open-external-link';
import { substituteRouteParams } from 'utils/routing/helpers';

const Invitation: React.FC = () => {
  const { t } = useTranslation();
  const { invitationId, userEmail } = useParams();
  const shouldCallAcceptInvitation = useRef(false);
  const {
    acceptInvitation,
    acceptInviteError,
    isAcceptInviteError,
    isAcceptingInvite,
    customError,
  } = useAcceptInviteMutation();

  const { invitationStatusError, isLoadingInvitationStatus, status } =
    useInvitationStatus(invitationId, userEmail);

  useEffect(() => {
    if (!invitationId || !userEmail) {
      return;
    }

    if (
      status?.status === InvitationStatus.NotAccepted &&
      shouldCallAcceptInvitation.current
    ) {
      acceptInvitation(invitationId, userEmail);
    }

    if (
      status?.status === InvitationStatus.InProgress &&
      status.verificationEmailText
    ) {
      openExternalLink(
        substituteRouteParams(FINISH_REGISTRATION_ROUTE, {
          token: status.verificationEmailText,
          userEmail,
        })
      );
    }

    shouldCallAcceptInvitation.current = true;
  }, [invitationId, status?.status, userEmail, status?.verificationEmailText]);

  if (!invitationId || !userEmail) {
    return (
      <Heading
        title={t('Invitation.Header.Error.InvalidInvitation')}
        variant="h1"
      />
    );
  }

  if (isAcceptingInvite || isLoadingInvitationStatus) {
    return (
      <Container>
        <Loading />
      </Container>
    );
  }

  if (status?.status === InvitationStatus.Finished) {
    return (
      <Container>
        <Heading
          title={t('Invitation.Header.Error.InvalidInvitation')}
          variant="h1"
        />
      </Container>
    );
  }

  if (isAcceptInviteError || !!invitationStatusError || customError) {
    return (
      <Container>
        {isAcceptInviteError && (
          <Box width="max-content">
            <Alert
              type="error"
              message={(acceptInviteError as Error)?.message}
            />
          </Box>
        )}
        {!!invitationStatusError && (
          <Box width="max-content">
            <Alert
              type="error"
              message={(invitationStatusError as Error)?.message}
            />
          </Box>
        )}
        {customError && <Alert type="error" message={customError} />}
      </Container>
    );
  }
  return null;
};

export const Container = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  margin-top: 30rem;
  gap: 3rem;
`;

export default Invitation;
