import { Breadcrumb, Breadcrumbs, Flex, showAlertModalError } from '@conteg/ui';
import { PageHeading } from 'components/page-heading/page-heading';
import { useInvalidateQueries } from 'hooks/use-invalidate-query';
import CompanyForm, {
  CompanyFormInputs,
} from 'pages/companies/company/components/company-form';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import {
  CompaniesDocument,
  CompanyType,
  useCreateCompanyMutation,
} from 'types/generated/graphql';
import { useAlertContext } from 'utils/alert-provider';
import { RouteUrls } from 'utils/routing/router';
import { postalCode } from 'utils/validations';
import { dic, ico, requiredString } from 'utils/validations/validations';
import { z } from 'zod';

const validationSchema = z.object({
  name: requiredString,
  city: requiredString,
  country: requiredString,
  postalCode: postalCode,
  street: requiredString,
  companyType: z.boolean(),
  ico: ico,
  dic: dic,
});

const CreateCompany = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { showSuccess } = useAlertContext();
  const invalidate = useInvalidateQueries();

  const { mutate: createCompany } = useCreateCompanyMutation({
    onSuccess: () => {
      showSuccess(t('CompanyForm.Form.Submit.Success'));
      invalidate([CompaniesDocument]);
      navigate(RouteUrls.Companies);
    },
    onError: (err) =>
      showAlertModalError(t('CompanyForm.Form.Submit.Error'), err),
  });

  const handleSubmit = async ({
    name,
    city,
    country,
    postalCode,
    street,
    companyType,
    ico,
    dic,
  }: CompanyFormInputs) => {
    createCompany({
      name,
      city,
      country,
      postalCode,
      street,
      type: companyType ? CompanyType.Big : CompanyType.Small,
      ico,
      dic,
    });
  };

  return (
    <Flex flexDirection="column" gap="6.25rem">
      <Breadcrumbs
        items={[
          {
            canGoBack: true,
            label: t('Breadcrumb.Home'),
            url: RouteUrls.Companies,
          },
          {
            canGoBack: true,
            label: t('Breadcrumb.AddCompany'),
            url: RouteUrls.CreateCompany,
          },
        ]}
        navigate={(to: Breadcrumb) => navigate(to.url)}
      />
      <PageHeading title={t('CompanyForm.Form.Header')} />
      <CompanyForm
        shouldEnableEdit
        validationSchema={validationSchema}
        title={t('CompanyForm.Form.Header')}
        onSubmit={handleSubmit}
        submitButtonLabel={t('CompanyForm.Form.SubmitButton')}
        onGoBack={() => navigate(RouteUrls.Companies)}
        hasNewCompanyFields
        defaultValues={{
          companyType: false,
        }}
      />
    </Flex>
  );
};

export default CreateCompany;
